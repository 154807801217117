<template>
  <div>
    <DoodleGeneralForm title=""
                       in-progress-message="JDoodle is trying to sign you in, Please wait..."
                       success-message="JDoodle is trying to sign you in, Please wait..."
                       action-text="Sign In"
                       hide-action-close="true"
                       :form="doodleForm"
                       :inProgress="doodleForm.inProgress" :completed="doodleForm.completed"
                       :errorMessageText="doodleForm.errorMessage"
                       @action="recaptchaAction">

      <div class="has-text-centered">
        <div class="margin-bottom-10px">
          <a class="button sign-in-google" @click="googleSignInForMobile">
            <img src="@/assets/images/google-symbol.svg"/> Sign In with Google
          </a>
        </div>
        <div class="margin-bottom-20px">
          <a class="button ms_style" @click="microsoftSignInForMobile">
            <img src="@/assets/images/ms-symbol.png"/>Sign in with Microsoft
          </a>
        </div>
      </div>
      <div class="is-centered margin-bottom-20px margin-top-20px">
        <div class="is-divider or-block has-background-black" data-content="OR"></div>
      </div>
      <DoodleInput field-name="username" label-text="Email ID" type="text" leftIcon="envelope"
                   v-model="doodleForm.username" @enter="recaptchaAction"
                   rule="required|email" :model="doodleForm.username" place-holder="joe@example.com"/>
      <DoodleInput field-name="password" label-text="Password" type="password" leftIcon="lock"
                   v-model="doodleForm.password" @enter="recaptchaAction"
                   rule="required" :model-name="doodleForm.password" place-holder="************"/>
      <div id="mobileLoginContainer" class="g-recaptcha" data-size="invisible"
           data-sitekey="6LfPlOsUAAAAAIALEFUM1022nNwsyWjpATeuYMdi"></div>
      <div class="has-text-centered just-registered has-text-weight-semibold">
        Just Registered? - Please check your email before login.
      </div>
      <div class="has-text-centered margin-top-20px">
      <button class="button is-info has-text-white has-text-weight-semibold action-button" @click="recaptchaAction" type="button">
        Sign In
      </button>
      </div>
    </DoodleGeneralForm>
    <div class="has-text-centered has-text-weight-semibold" v-if="!doodleForm.inProgress && !doodleForm.completed">
        New to JDoodle.com?<br/> Want to register with Email?<br/> Register an account in www.jdoodle.com from a browser.
    </div>
  </div>
</template>

<script>
import formMixin from '@/assets/javascript/form-mixin'
import oauthMixin from '@/assets/javascript/oauth-mixin'
import recaptchaMixin from '@/assets/javascript/recaptcha-mixin'
import DoodleInput from '../../components/Utils/DoodleInput'
import DoodleGeneralForm from '../../components/Utils/DoodleGeneralForm'

export default {
  name: 'login',
  mixins: [formMixin, oauthMixin, recaptchaMixin],
  inject: ['$validator'],
  components: { DoodleGeneralForm, DoodleInput },
  data: function () {
    return {
      doodleForm: {
        username: null,
        password: null
      }
    }
  },
  mounted () {
    if (this.$store.state.isUserLoggedIn === true) {
      this.$router.push('/mobile-app/login-success')
    }

    this.loadRecaptcha('mobileLoginContainer', this.signIn)
  },
  methods: {
    signIn (gc) {
      if (!gc) {
        this.doodleForm.errorMessage = 'Robot check failed. Please try refresh the page or contact JDoodle support at hello@jdoodle.com.'
        return
      }

      this.executeAPI({
        url: '/api/login/authenticate',
        data: {
          username: this.doodleForm.username,
          password: this.doodleForm.password,
          recaptcha: gc
        },
        method: 'post',
        successAction: (data) => {
          if (data.error) {
            this.doodleForm.errorMessage = data.error
            this.doodleForm.completed = false
          } else {
            this.$router.push('/mobile-app/login-success/' + this.$route.params.token)
          }
        },
        markCompleted: true,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.GLOBAL,
        jdaEvent: 'login',
        jdaLabel: ''
      })
      window.grecaptcha.reset()
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../node_modules/bulma-divider/dist/css/bulma-divider.min.css";
@import "../../assets/style/oauth";

.new-to-message {
  margin-right: 1em;
}

.is-divider {
  margin: 0;
}

.just-registered {
  font-size: 0.8em;
}

.or-block {
  margin-top: 1em;
  margin-bottom: 1em;
}

</style>
